// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://miras-cargo-astana.kz:3001',
    
    nameCargo: 'Miras-Cargo-Astana'
  };
  
  
export default config;
  